'use strict';

/* Index ----------------------------------------

* Variables
* Hamburger navigation for mobile

------------------------------------------------- */

/* ----------------------------------------------
  Variables
------------------------------------------------- */

const bodyEl = document.body;
const doc    = document.documentElement;
const root   = getComputedStyle(doc);

// Id & Class
const header_class       = '.header';
const header_nav_id       = 'js-header-nav';
const hambIcon_id         = 'js-hamburger';
const overlay_id          = 'js-overlay';
const accordion_trigger   = 'accordion-trigger';
const active_class        = 'active';
const opened_class        = 'opened';
const closed_class        = 'closed';


/* ----------------------------------------------
  Hamburger navigation for mobile
------------------------------------------------- */
function setHamburger() {
  const nav = document.getElementById(header_nav_id);
  const hambIcon = document.getElementById(hambIcon_id);
  const header = document.querySelector(header_class);

  // create overlay
  const overlay = document.createElement('div');
  overlay.setAttribute('id', 'overlay');
  overlay.classList.add('overlay');
  document.body.append(overlay);

  const toggle = (elm) => {
    elm.addEventListener('click', (e) => {
      e.preventDefault();
      if (header?.classList.contains(active_class)) {
        close(header);
      } else {
        open(header);
      }
    });
  }

  const open = (target) => {
    console.log('open');
    target.classList.add(active_class);
    overlay?.classList.add(active_class);
  }

  const close = (target) => {
    console.log('close');
    target.classList.remove(active_class);
    overlay?.classList.remove(active_class);
  }

  // ハンバーガーアイコンをクリックすると、メニューを開く
  toggle(hambIcon);
  toggle(overlay);
}

(function () {
  setHamburger();
}());