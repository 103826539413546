'use strict';
/* Index ----------------------------------------

* Variables
* Hamburger navigation for mobile

------------------------------------------------- */
/* ----------------------------------------------
  Variables
------------------------------------------------- */
var bodyEl = document.body;
var doc = document.documentElement;
var root = getComputedStyle(doc);
// Id & Class
var header_class = '.header';
var header_nav_id = 'js-header-nav';
var hambIcon_id = 'js-hamburger';
var overlay_id = 'js-overlay';
var accordion_trigger = 'accordion-trigger';
var active_class = 'active';
var opened_class = 'opened';
var closed_class = 'closed';
/* ----------------------------------------------
  Hamburger navigation for mobile
------------------------------------------------- */
function setHamburger() {
    var nav = document.getElementById(header_nav_id);
    var hambIcon = document.getElementById(hambIcon_id);
    var header = document.querySelector(header_class);
    // create overlay
    var overlay = document.createElement('div');
    overlay.setAttribute('id', 'overlay');
    overlay.classList.add('overlay');
    document.body.append(overlay);
    var toggle = function (elm) {
        elm.addEventListener('click', function (e) {
            e.preventDefault();
            if (header === null || header === void 0 ? void 0 : header.classList.contains(active_class)) {
                close(header);
            }
            else {
                open(header);
            }
        });
    };
    var open = function (target) {
        console.log('open');
        target.classList.add(active_class);
        overlay === null || overlay === void 0 ? void 0 : overlay.classList.add(active_class);
    };
    var close = function (target) {
        console.log('close');
        target.classList.remove(active_class);
        overlay === null || overlay === void 0 ? void 0 : overlay.classList.remove(active_class);
    };
    // ハンバーガーアイコンをクリックすると、メニューを開く
    toggle(hambIcon);
    toggle(overlay);
}
(function () {
    setHamburger();
}());
